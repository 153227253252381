<template>
  <div class="hero-slider-image glide">
    <div data-glide-el="track" class="glide__track">
      <ul class="glide__slides">
        <li
          v-for="(card, index) in sliderData"
          class="glide__slide"
          :key="`${card.cta}-${index}`"
        >
          <nuxt-img
            :alt="card.cta || `card-${index}`"
            :src="card.images.desktop"
            :srcset="`${card.images.mobile || card.images.desktop} 480w, ${
              card.images.tablet || card.images.desktop
            } 768w, ${card.images.desktop} 1024w`"
            sizes="(max-width: 480px) 480px, (max-width: 768px) 768px, 1024px"
            class="hero-slider-image__img"
            placeholder="/assets/product_placeholder.svg"
          />
        </li>
      </ul>
      <div class="glide__arrows" data-glide-el="controls">
        <button
          class="glide__arrow glide__arrow--left"
          :class="currentIndex === 0 && 'disable-arrow'"
          data-glide-dir="<"
        >
          <ArrowLeft
            alt="hs_arrow-left"
            :viewBox="isMobile ? '0 0 64 64' : '0 0 44 44'"
          />
        </button>
        <button
          class="glide__arrow glide__arrow--right"
          :class="currentIndex + 1 === sliderData.length && 'disable-arrow'"
          data-glide-dir=">"
        >
          <ArrowRight
            alt="hs_arrow-right"
            :viewBox="isMobile ? '0 0 64 64' : '0 0 44 44'"
          />
        </button>
      </div>
    </div>
    <div class="glide__bullets" data-glide-el="controls[nav]">
      <button
        v-for="(card, index) in sliderData"
        :key="`${card.cta}-${index}`"
        class="glide__bullet"
        :class="currentIndex === index && 'glide__bullet--active'"
        :data-glide-dir="`=${index}`"
      ></button>
    </div>
  </div>
</template>

<script>
import {
  defineComponent,
  ref,
  onMounted,
  computed,
} from '@nuxtjs/composition-api';
import Glide, {
  Controls,
  Autoplay,
  Swipe,
  Images,
} from '@glidejs/glide/dist/glide.modular.esm';
import { ArrowLeft, ArrowRight } from '~/static/icons';
import { mapMobileObserver } from '~/helpers/mobileObserver';

export default defineComponent({
  name: 'HeroSliderImage',
  components: { ArrowLeft, ArrowRight },
  props: {
    sliderData: {
      type: Array,
      required: true,
    },
  },
  setup(_, { emit }) {
    const currentIndex = ref(0);
    const isMobile = computed(() => mapMobileObserver().isMobile.get());

    onMounted(() => {
      const glide = new Glide('.glide', {
        type: 'carousel',
        autoplay: 5000,
      });

      glide.on('run.after', () => {
        currentIndex.value = glide.index;
        emit('change-slide', currentIndex.value);
      });

      glide.mount({ Controls, Autoplay, Swipe, Images });
    });

    return {
      currentIndex,
      isMobile,
    };
  },
});
</script>

<style lang="scss">
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.core';
@import 'node_modules/@glidejs/glide/src/assets/sass/glide.theme';

.hero-slider-image {
  grid-column: 33 / 100;
  z-index: 0;
  max-height: 95%;

  .glide__track {
    height: 100%;
    @media (min-width: $desktop-min) {
      position: relative;
    }

    .glide__slides {
      height: 100%;
      .glide__slide {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }

  // make bullet red as the rest of the site
  .glide__bullets {
    bottom: -2em;
    .glide__bullet {
      background-color: #d41025;
      opacity: 0.5;
      &--active {
        background-color: #d41025;
        opacity: 1;
      }
    }
  }

  .glide__arrow {
    box-shadow: none;
    padding: 0;
    width: 60px;
    height: 60px;
    border: 0.5px solid #d41025;
    color: #d41025;
    border-radius: 3px;
    font-size: 1.5rem;

    svg {
      display: none;
    }

    &--right {
      &:after {
        content: '>';
      }
    }

    &--left {
      right: 5em;
      &:after {
        content: '<';
      }
    }

    &:hover {
      background-color: #d41025;
      color: white;
    }

    &.disable-arrow {
      pointer-events: none;
      opacity: 0.2;
    }

    @media (min-width: $desktop-min) {
      &.glide__arrow--left {
        right: 5em;
        left: unset;
      }
    }
    @media (max-width: $tablet-max) {
      scale: 0.5;
    }
  }

  &__img {
    width: 100%;
    max-height: 100%;
    object-fit: contain;
  }
}

@media (max-width: $tablet-max) {
  .hero-slider-image {
    grid-column: 1 / 2;
  }
}
</style>
