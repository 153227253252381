<template>
  <div class="product-slider">
    <div v-if="!loadingWishlist">
      <div v-if="!isDesktop">
        <HeroSliderBannerSplitColorsVue
          class="slider-title"
          :black-part="title.black"
          :red-part="title.red"
        />
      </div>
      <SfCarousel :settings="sliderSettings">
        <slot>
          <SfCarouselItem v-for="(product, key) in mappedProducts" :key="key">
            <SfProductCardCustom
              v-if="product"
              :product-entity="product"
              :is-in-wishlist="!!itemsInWishlist[product.sku]"
              @clickWishlist="toggleWishlist(product, mappedProducts)"
            />
          </SfCarouselItem>
        </slot>
        <template #next="{ go }" v-if="isDesktop">
          <ArrowRightDark
            class="product-slider-arrow product-slider-arrow-right"
            alt="test"
            @click="go('next')"
          />
        </template>
        <template #prev="{ go }" v-if="isDesktop">
          <HeroSliderBannerSplitColorsVue
            class="slider-title"
            :black-part="title.black"
            :red-part="title.red"
          />
          <ArrowLeftDark
            class="product-slider-arrow product-slider-arrow-left"
            @click="go('prev')"
          />
        </template>
      </SfCarousel>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted, ref } from '@nuxtjs/composition-api';
import SfProductCardCustom from '~/components/SfProductCardCustom.vue';
import { SfCarousel } from '@storefront-ui/vue';
import { useProduct } from '@gemini-vsf/composables';
import { ArrowLeftDark, ArrowRightDark } from '~/static/icons';
import { useWishlist } from '~/composables';
import HeroSliderBannerSplitColorsVue from './HeroSlider/HeroSliderBannerSplitColors.vue';

export default defineComponent({
  name: 'ProductSlider',
  components: {
    SfProductCardCustom,
    SfCarousel,
    ArrowLeftDark,
    ArrowRightDark,
    HeroSliderBannerSplitColorsVue,
  },
  props: {
    querySettings: {
      type: Object,
      default: () => {
        return {
          pageSize: 10,
          currentPage: 1,
          sort: {
            position: 'ASC',
          },
          customQuery: {
            products: 'productListCustom',
          },
        };
      },
    },
    sliderSettings: {
      type: Object,
      default: () => {
        return {
          type: 'slider',
          perView: 4,
          peek: 0,
          gap: 15,
          breakpoints: {
            449: {
              perView: 1,
              gap: 45,
            },
            767: {
              perView: 1,
              gap: 45,
            },
            1023: {
              perView: 2,
              peek: 0,
              gap: 25,
            },
          },
        };
      },
    },
    query: {
      type: String,
      default: 'newProducts',
    },
    title: {
      type: Object,
      default: () => {},
    },
    isDesktop: Boolean,
  },
  setup(props) {
    const mappedProducts = ref([]);
    const { search: getProductList, products } = useProduct(props.query);
    const {
      loadItemsFromList,
      itemsInWishlist,
      toggleWishlist,
      loadingWishlist,
    } = useWishlist();

    onMounted(async () => {
      await getProductList(props.querySettings);

      if (products.value?.items?.length) {
        // filter out of stock items
        const inStockItems = products.value.items.filter(
          (e) => e.stock_status === 'IN_STOCK'
        );
        mappedProducts.value = inStockItems;
        await loadItemsFromList(mappedProducts.value);
      }
    });

    return {
      mappedProducts,
      itemsInWishlist,
      toggleWishlist,
      loadingWishlist,
    };
  },
});
</script>

<style lang="scss">
.product-slider {
  padding-top: 50px;
  padding-bottom: 50px;
  background: linear-gradient(
    to bottom,
    var(--c-white) 35%,
    var(--c-grey-1) 35%
  );

  .slider-title {
    font-family: var(--font-family--primary);
    font-size: var(--font-size--ml);
    letter-spacing: -1.2px;
    line-height: var(--font-size--2xl);
    margin-right: auto;
    font-weight: var(--font-weight-semibold);
    &.hero-slider-banner-split-colors {
      padding: 0;
    }
  }
  .product-slider-skeletons {
    display: flex;
    gap: 10px;
    justify-content: space-around;
    .product-slider-skeleton {
      height: 630px;
      width: 306px;
    }
  }
  .sf-carousel {
    .sf-carousel__wrapper {
      margin: 0;
      padding: 0 15px;
      @media (min-width: 1440px) and (max-width: 1700px),
        (min-width: 1024px) and (max-width: 1280px),
        (max-width: 390px) {
        .product-card .product-card-bottom {
          width: 16.25rem;
        }
      }
    }
    .sf-carousel__controls {
      top: -85px;
      justify-content: flex-end;
      align-items: center;
      .product-slider-arrow-left {
        margin-right: 15px;
      }
    }
  }

  .product-slider-arrow {
    box-shadow: none;
    padding: 0;
    width: 44px;
    height: 44px;
    border: 1px solid var(--c-grey-2);
    background-color: var(--c-white);
    border-radius: 3px;
    &:hover {
      background-color: var(--c-grey-9);
      cursor: pointer;
    }
  }
}

//big desktop
@media (min-width: $desktop-xxl-min) {
  .product-slider {
    margin: 0 auto;
    .sf-carousel {
      max-width: var(--width-boxed);
      margin: 0 auto;
      .sf-carousel__wrapper {
        overflow: unset;
        --carousel-width: 100%;
      }
    }
  }
}

//small desktop
@media (min-width: $desktop-min) and (max-width: ($desktop-xxl-min - 1)) {
  .product-slider {
    margin: 0 auto;
    .sf-carousel {
      width: 80%;
      margin: 0 auto;
      .sf-carousel__wrapper {
        overflow: unset;
        --carousel-width: 100%;
        .sf-carousel-item {
          .product-card {
            .product-card-top {
              .product-card-image {
                img {
                  transform: scale(0.7);
                }
              }
            }
          }
        }
      }
    }
  }
}

//tablet
@media (min-width: $tablet-min) and (max-width: $tablet-max) {
  .product-slider {
    padding: 20px 0 105px;
    .slider-title {
      font-size: var(--font-size--sm);
      width: fit-content;
      margin: 0 auto 50px auto;
    }
    .sf-carousel {
      margin: 0 var(--tablet-margin);
      .sf-carousel-item {
        .product-card {
          .custom-button.primary {
            width: max-content;
            font-size: var(--font-size--4xs);
            padding: 1vw;
          }

          .custom-button.secondary {
            .sf-icon-path {
              height: 1.5vw;
              width: 1.5vw;
            }
          }
        }
      }
    }
  }
}

//mobile
@media (max-width: $mobile-max) {
  .product-slider {
    padding: 20px 0 105px;
    .slider-title {
      font-size: var(--font-size--sm);
      width: fit-content;
      margin: 0 auto 50px auto;
    }
    .sf-carousel {
      margin: 0 var(--mobile-margin);
      .sf-carousel-item {
        .product-card {
          max-width: 326px;
          .custom-button.primary {
            font-size: var(--font-size--base);
          }
        }
      }
    }
  }
}
</style>
